<template>
  <div class="landing-container">
    <div class="title">Silas Ranger</div>
    <div class="tagline">Silas Ranger is a private fund as per Section 203(m) and Rule 203(m)-1 of the Security Exchange Commission.</div>
  </div>
</template>

<script>
export default {
  name: 'LandingPage',
};
</script>

<style scoped>
@font-face {
  font-family: 'Arkhip';
  src: url('@/assets/fonts/Arkhip-Regular.otf') format('opentype');
}

html, body {
  margin: 0;
  padding: 0;
  height: 100%;
  width: 100%;
  overflow: hidden;
}

.landing-container {
  text-align: center;
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: #000;
  color: #ffffff;
  font-family: 'Arkhip', sans-serif;
  padding: 20px;
  box-sizing: border-box;
}

.title {
  font-size: 4em;
  margin-bottom: 20px;
  text-align: center;
}

.tagline {
  font-size: 1em;
  font-family: 'Times New Roman', Times, serif;
}

/* Responsive styles */
@media (max-width: 768px) {
  .title {
    font-size: 3em;
  }

  .tagline {
    font-size: 1.2em;
  }
}

@media (max-width: 480px) {
  .title {
    font-size: 2em;
  }

  .tagline {
    font-size: 1em;
  }
}
</style>